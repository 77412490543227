import axios from '@/config/httpConfig'

// 检查域名可用性
export function checkPartnerFirst (data) {
  return axios.post('/partnerSetup/checkPartnerFirst', data)
}

// 查询伙伴云设置
export function queryPartnerSetup (data) {
  return axios.get('/partnerSetup/queryPartnerSetup', data)
}

// 检查伙伴云数量
export function checkPartnerNum (data) {
  return axios.post('/partnerSetup/checkPartnerNum', data)
}

// 启用禁用伙伴云工作区
export function savePartnerCommonSetup (data) {
  return axios.post('/partnerSetup/savePartnerCommonSetup', data)
}

// 公告发布范围
// 获取共享规则列表
export function getShareRuleList (data) {
  return axios.post("/share/getShareRuleList", data);
}

// 搜索用户组
export function searchGroup (data) {
  return axios.post("/share/searchGroup", data);
}

// 保存公告发布范围
export function saveShareRule (data) {
  return axios.post("/share/saveShareRule", data);
}

// 删除发布列表人员
export function deleteShareRule (data) {
  return axios.post("/share/deleteShareRule", data);
}
// 查询伙伴云列表
export function queryPartnerList (data) {
  return axios.get('/partnerSetup/queryPartnerList', data)
}

// 查询伙伴云一级域名
export function getPartnerFirstUrl (data) {
  return axios.get('/partnerSetup/getPartnerFirstUrl', data)
}

// 保存伙伴云
export function savePartner (data) {
  return axios.post('/partnerSetup/savePartner', data)
}

// 根据id查询伙伴云
export function queryPartnerById (data) {
  return axios.post('/partnerSetup/queryPartnerById', data)
}

// 获取伙伴云简档及权限集
export function queryPermissionList (data) {
  return axios.post('/partnerSetup/queryPermissionList', data)
}

// 搜索未选择简档
export function queryProfileListJson (data) {
  return axios.post('/partnerSetup/queryProfileListJson', data)
}

// 保存成员简档信息
export function savePartnerMember (data) {
  return axios.post('/partnerSetup/savePartnerMember', data)
}

// 用户菜单查询
export function register (data) {
  return axios.post('/partnerSetup/queryPartnerTab', data)
}
// 保存菜单
export function savePartnerTab (data) {
  return axios.post('/partnerSetup/savePartnerTab', data)
}
// 伙伴云初始化
export function savePartnerSetup (data) {
  return axios.post('/partnerSetup/savePartnerSetup', data)
}

// 根据id查询伙伴云登陆页面配置
export function queryPartnerLoginSetupById (data) {
  return axios.post('/partnerSetup/queryPartnerLoginSetupById', data)
}

// 图片字段上传
export function uploadImg (data) {
  return axios.post('file/uploadImg', data)
}

// 清除照片
export function removeImg (data) {
  return axios.post('file/removeImg', data)
}


// 查询支付设置
export function getPaymentSettings (data) {
  return axios.post('partnerSetup/getPaymentSettings', data)
}

// 线上支付设置
export function savePaymentSetting (data) {
  return axios.post('partnerSetup/savePaymentSetting', data)
}

// 查询微信支付配置信息
export function getWXpayMessage (data) {
  return axios.post('partnerSetup/getWXpayMessage', data)
}

// 保存微信支付商家信息
export function saveWXPayMessage (data) {
  return axios.post('partnerSetup/saveWXPayMessage', data)
}

// 查询支付宝支付配置信息
export function getALiPayMessage (data) {
  return axios.post('partnerSetup/getALiPayMessage', data)
}

// 保存支付宝支付商家信息
export function saveALiPayMessage (data) {
  return axios.post('partnerSetup/saveALiPayMessage', data)
}

// 校验是否超过限额
export function checkIsRecharge (data) {
  return axios.post('PayApi/checkIsRecharge', data)
}

// 充值下单
export function doUnifiedOrder (data) {
  return axios.post('PayApi/doUnifiedOrder', data)
}

// 充值是否完成
export function isRechargeSuccess (data) {
  return axios.post('PayApi/isRechargeSuccess', data)
}

// 关闭订单
export function clouseOrder (data) {
  return axios.post('PayApi/clouseOrder', data)
}
//保存伙伴账户设置
export function savePartnerFund (data) {
  return axios.post('/partnerFund/savePartnerFund', data)
}
// 查询伙伴账户与流水
export function queryPartnerFund (data) {
  return axios.post('partnerFund/queryPartnerFund', data)
}
// 检查二级域名可用性
export function checkPartnerSecond (data) {
  return axios.post('/partnerSetup/checkPartnerSecond', data)
}
//查询伙伴账户启用设置
export function queryPartnerFundSetup (data) {
  return axios.get('/partnerFund/queryPartnerFundSetup', data)
}

/*下单平台接口*/
//下单平台查询价格手册
export function queryPricingTable (data) {
  return axios.post('/partnerorder/queryPricingTable', data)
}

//产品筛选
export function queryProduct (data) {
  return axios.post('/partnerorder/queryProduct', data)
}

//清空购物车
export function emptyShoppingCart (data) {
  return axios.post('/partnerorder/emptyShoppingCart', data)
}
// 查询客户筛选条件
export function getAccountCondition (data) {
  return axios.post('/partnerFund/getAccountCondition', data)
}
// 保存客户筛选条件
export function saveAccountCondition (data) {
  return axios.post('/partnerFund/saveAccountCondition', data)
}

//查询伙伴云产品系列接口
export function getProductSort (data) {
  return axios.post("/partnersort/getProductSort", data);
}

//加入购物车
export function SaveShoppingCart (data) {
  return axios.post('/partnerorder/SaveShoppingCart', data)
}

//查询购物车
export function queryShopping (data) {
  return axios.post('/partnerorder/queryShopping', data)
}

//从购物车删除产品
export function deleteShoppingCart (data) {
  return axios.post('/partnerorder/deleteShoppingCart', data)
}

// //展示产品列表
// export function showProductList (data) {
//   return axios.post('/cpq/productconfig/showProductList', data)
// }

//展示产品列表伙伴云
export function showProductList (data) {
  return axios.post('/partnerorder/getProductList', data)
}

//查询选中的产品的产品选项信息
export function productionOptionInfo (data) {
  return axios.post('/cpq/productconfig/productionOptionInfo', data)
}

//展示产品配置列表
export function showProductConfigList (data) {
  return axios.post('/cpq/productconfig/showProductConfigList', data)
}


// 生成订单
export function createOrder (data) {
  return axios.post('/partnerorder/generateOrders', data)
}
// 获取支付方式
export function getPayMethod (data) {
  return axios.post('partnerorder/getPayMethod', data)
}


// 查询返利收入
export function queryRebateRevenue (data) {
  return axios.post('/partnerFund/queryRebateRevenue', data)
}

// 查询返利支出
export function queryRebateExpenditure (data) {
  return axios.post('/partnerFund/queryRebateExpenditure', data)
}
// 查询是否交易成功
export function isOrderSuccess (data) {
  return axios.post('/PayApi/isOrderSuccess', data)
}
// 展示折扣计划
export function showDiscountSchedule (data) {
  return axios.post('/cpq/productconfig/showDiscountSchedule', data)
}
// 查询购物车父产品数量
export function getProductNum (data) {
  return axios.post('/partnerorder/getProductNum', data)
}
// 获取订单支付状态
export function getPaymentStatus (data) {
  return axios.post('/partnerorder/getPaymentStatus', data)
}
// 查询交易状态
export function getTradeStatus (data) {
  return axios.post('/PayApi/getTradeStatus', data)
}
// 获取CCChat后台设置信息
export function getSetupInfo () {
  return axios.post('/ccchat/person/getSetupInfo')
}
// 查询返利收入可用返利余额临期记录
export function queryRecentlyRevenue (data) {
  return axios.get('partnerFund/queryRecentlyRevenue', data)
}
// 支付订单查询
export function queryOrders (data) {
  return axios.post('/partnerorder/queryOrders', data)
}
// 提交待审批
export function submitApproval(args) {
  return axios.post('approval/submitApproval', args)
}
// 查询购物车中选中的商品
export function queryChooseProduct(args) {
  return axios.post('partnerorder/queryChooseProduct', args)
}
// 从购物车删除已生成订单的产品
export function deletechooseproduct(args) {
  return axios.post('partnerorder/deletechooseproduct', args)
}

// 获取记录类型
export function getRecordType(data) {
  return axios.post('/batch/getRecordType', data)
}

// 获取新建事件相关项
export function getDialigItem(data) {
  return axios.post('activity/getQuickActivityPage', data)
}

// 获取查找信息
export function getLookupInfo(data) {
  return axios.post('/lookup/getLookupInfo', data)
}

// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
  return axios.post('objectdetail/queryMoreDataByName', data)
}

// 获取对象详细信息
export function getDetail(data) {
  return axios.post('/objectdetail/getDetail', data)
}

// 查询动态布局规则
export function getDynamicLayoutListDetail(data) {
  return axios.post('/objectdetail/getDynamicLayoutListDetail', data)
}

// 获取选项列表值
export function getSelectValue(data) {
  return axios.post('/view/getSelectValue', data)
}

// 添加或更新对象记录
export function save(data) {
  return axios.post('/objectInfo/save', data)
}

//获取对象记录权限
export function getPermissionById(data) {
  return axios.post('/objectdetail/getPermissionById', data)
}

// 主从录入表头
export function getMasterInfo(data) {
  return axios.post('/master/getMasterInfo', data)
}

// 查找带值
export function getLookupRelatedFieldValue(data) {
  return axios.post('/lookup/getLookupRelatedFieldValue', data)
}

// 删除记录
export function del(data) {
  return axios.post('/objectdetail/delete', data)
}