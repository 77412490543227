<template>
  <div>
    <div
      class="product list"
      :style="{
        background: checked ? '#F3F7FF' : '#FFF',
      }"
    >
      <div
        style="position: absolute; left: 40px; top: 0; cursor: pointer"
        v-show="item.childproduct && item.childproduct.length > 0"
        @click="showChild = !showChild"
      >
        <svg class="icon xiala" aria-hidden="true">
          <use :href="showChild ? '#icon-xiala' : '#icon-a-Putaway1'"></use>
        </svg>
      </div>
      <div class="item" style="width: 64px; height: 114px">
        <el-checkbox
          :value="item.checked"
          v-show="item.parentproduct == 'null'"
        >
        </el-checkbox>
      </div>
      <div class="item picture">
        <el-image
          :src="oldImgSrcBefore + item.imageid + oldImgSrcAfter"
          alt=""
          v-if="item.imageid"
          :preview-src-list="[oldImgSrcBefore + item.imageid + oldImgSrcAfter]"
        ></el-image>
      </div>
      <div class="item productname">
        {{ item.productname }}
      </div>
      <div class="item cpdm">
        {{ item.productcode }}
      </div>
      <div class="item number">
        {{ thousands(item.listprice) }}
      </div>
      <div class="item count" v-if="item.quantityeditable == 'false'">
        {{ Math.floor(item.quantity) }}
      </div>
      <div class="item count" v-else>
        <div class="add-cars">
          <div class="car-button reduce" @click="reduceNum(item)">-</div>
          <input
            type="text"
            :value="item.quantity"
            @blur="hiddenInput(item.id, item.quantity)"
            :id="item.id"
            onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
          />
          <!-- {{ Math.floor(item.quantity) }} -->
          <div class="car-button add" @click="addNum(item)">+</div>
        </div>
      </div>
      <div class="item zhekou">
        <el-popover
          @show="showDiscount(item.discountschedule)"
          placement="bottom"
          width="700"
          trigger="hover"
          popper-class="popper_report"
        >
          <div class="bodyContent">
            <!-- 头部 -->
            <div class="contentHeader">
              <div>
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-a-DiscountProgram"></use>
                </svg>
              </div>
              <!-- 折扣计划 -->
              <div>
                {{ $t("label.partnerCloud.orderingPlatform.discounts") }}
              </div>
            </div>
            <div class="borderTop"></div>
            <!-- 主体 -->
            <div class="contentBody" v-loading="discountLoading">
              <!-- 配置 -->
              <div class="option" style="justify-content: space-between">
                <div class="option">
                  <!-- 折扣计划名称： -->
                  <div>
                    <span>{{
                      $t("label.partnerCloud.orderingPlatform.discountPlan")
                    }}</span>
                  </div>
                  <div>
                    {{ discountName }}
                  </div>
                </div>
                <div class="option">
                  <div>
                    <!-- 折扣单位： -->
                    <span
                      >{{
                        $t("label.partnerCloud.orderingPlatform.discountUnit")
                      }}{{ discountUnit }}</span
                    >
                  </div>
                </div>
              </div>
              <!-- 表格 -->
              <div class="dataTable">
                <el-table
                  border
                  max-height="250"
                  :empty-text="$t('label.weixin.nomore')"
                  :data="listsData"
                >
                  <el-table-column
                    v-for="(items, index) in headList"
                    :key="index"
                    sortable
                    :align="items.apiname == 'name' ? 'left' : 'right'"
                    :label="items.label"
                    :prop="items.apiname"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
          <!-- 折扣 -->
          <div slot="reference" v-show="item.discountschedule">
            <svg class="icon discount-small" aria-hidden="true">
              <use href="#icon-discount"></use>
            </svg>
          </div>
        </el-popover>
        {{ thousands(item.discount) }}
      </div>
      <div class="item number">
        {{ thousands(item.customertotal) }}
      </div>
      <div class="item number">
        {{ thousands(item.retaprice) }}
      </div>
      <div class="item number">
        {{ thousands(item.zongjia) }}
      </div>
      <div
        class="item discount"
        @click="deleteSelectCar(item.product, item)"
        v-show="child == false"
      >
        <svg class="icon" aria-hidden="true">
          <use
            :href="item.checked ? '#icon-deleteQuotation' : '#icon-deletes'"
          ></use>
        </svg>
      </div>
    </div>
    <template
      v-if="item.childproduct && item.childproduct.length > 0 && showChild"
    >
      <order-car-table
        v-for="(childItem, childIndex) in item.childproduct"
        :key="childIndex"
        :item="childItem"
        :child="true"
        :checked="checked"
        :allDataArr="allDataArr"
        :checkAll="checkAll"
      ></order-car-table>
    </template>
  </div>
</template>

<script>
import { showDiscountSchedule } from "../api.js";
export default {
  name: "orderCarTable",
  props: {
    //表格数据
    item: {
      type: Object,
      default: () => {},
    },
    //所有父元素id
    allDataArr: {
      type: Array,
      default: () => [],
    },
    checkAll: {
      type: Boolean,
      default: false,
    },
    isSelectData: {
      type: Array,
      default: () => [],
    },
    child: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      return JSON.parse(JSON.stringify(this.item));
    },
  },
  data() {
    return {
      itemDataQua: this.item.quantity,
      oldImgSrcBefore: `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=`,
      oldImgSrcAfter: `&binding=${this.$CCDK.CCToken.getToken()}`,
      // 折扣计划名称
      discountName: this.$i18n.t("label.tabpage.nothingz"), //无
      //折扣单位
      discountUnit: this.$i18n.t("label.tabpage.nothingz"), //无
      listsData: [],
      headList: [],
      //折扣弹框loading
      discountLoading: false,
      //子级是否展示
      showChild: false,
      //当前展示输入框的下标
      inputIndex: null,
    };
  },
  watch: {
    items: {
      handler(newVal, oldVal) {
        if (newVal.checked != oldVal.checked) {
          this.$emit("changeCheckout", newVal);
        }
      },
      deep: true,
    },
  },
  methods: {
    //隐藏input
    hiddenInput(id, quantity) {
      if (quantity == "") {
        this.$message.warning(this.$i18n.t("label.numberscannotnull"));
        let inputItem = document.getElementById(id);
        inputItem.value = "";
        inputItem.focus();
        return false;
      }
      if (this.isSelectData >= 100) {
        this.$message.warning(this.$i18n.t("c34"));
        let inputItem = document.getElementById(id);
        inputItem.value = "";
        inputItem.focus();
        return false;
      }
      this.itemDataQua = quantity;
      this.$Bus.$emit("query-car-data");
    },
    // 设置千分位样式  dj
    thousands(num) {
      return (
        num &&
        (num.toString().indexOf(".") != -1
          ? num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
              return $1 + ",";
            })
          : num.toString().replace(/(\d)(?=(\d{3}))/g, function ($0, $1) {
              return $1 + ",";
            }))
      );
    },
    deleteSelectCar(product, item) {
      this.$emit("deleteSelectCar", product, item);
    },
    //展示折扣弹框
    async showDiscount(discountschedule) {
      if (!discountschedule) {
        this.discountUnit = this.$i18n.t("label.tabpage.nothingz"); //无
        this.discountName = this.$i18n.t("label.tabpage.nothingz"); //无
        this.headList = [];
        this.listsData = [];
        return false;
      }
      this.discountLoading = true;
      let res = await showDiscountSchedule({
        discountscheduleid: discountschedule,
      });
      if (res.result) {
        this.discountLoading = false;
        this.discountUnit = res.data.discountunit;
        this.discountName = res.data.schedulename;
        this.headList = res.data.tableLabel;
        res.data.schedulelist.forEach((item) => {
          item.discount = this.thousands(item.discount);
          item.upperbound = this.thousands(item.upperbound);
          item.discountamount =
            res.data.discountunit == "Percent(%)" ||
            res.data.discountunit == "百分比(%)"
              ? Number(item.discount)
              : Number(item.discountamount).toFixed(2);
          item.discountamount = this.thousands(item.discountamount);
        });
        this.listsData = res.data.schedulelist;
      }
    },
    //购物车产品减少数量
    reduceNum(item) {
      //如果数量等于1，再减少变为添加购物车按钮
      if (item.quantity == 1) {
        return;
      } else {
        item.quantity--;
        this.$Bus.$emit("query-car-data");
      }
    },
    //购物车产品添加数量
    addNum(item) {
      if (this.isSelectData >= 100) {
        this.$message.warning(this.$i18n.t("c34"));
        return false;
      }
      item.quantity++;
      this.$Bus.$emit("query-car-data");
    },
  },
};
</script>

<style lang='scss' scoped>
.product {
  line-height: 40px;
  height: 40px;
  overflow: hidden;
  .item {
    // text-align: center;
    float: left;
    width: 120px;
    border-right: 1px solid #dcdfe6;
    height: 100%;
    padding: 0 10px;
  }
  .discount-small {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  ::v-deep.el-popover__reference {
    display: inline-block;
  }
  ::v-deep.el-popover__reference-wrapper {
    display: inline-block;
  }
  input {
    outline: none;
    height: 30px;
    border-radius: 4px;
    border: 1px solid #dcdcdc;
    padding-left: 5px;
    box-sizing: border-box;
    text-align: center;
    border: none;
    width: 60px;
  }
  .zhekou {
    width: 120px;
    text-align: right;
  }
  .count {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    img {
      margin-left: 5px;
    }
  }
  .discount img {
    width: 20px;
    cursor: pointer;
  }
  .picture {
    display: flex;
    width: 142px;
    padding: 5px 10px;
  }
  .productname {
    width: calc(100% - 1227px);
  }
  .cpdm {
    width: 217px;
  }
  ::v-deep.picture img {
    width: 95px;
  }
  // .item:nth-child(1) {
  //   width: 42px;
  // }
  .item:last-child {
    border: none;
    width: 64px;
    cursor: pointer;
  }
  .titles:last-child {
    text-align: center;
  }
}
.list {
  position: relative;
  border-left: 1px solid #dcdfe6;
  border-right: 1px solid #dcdfe6;
  height: 114px;
  border-bottom: 1px solid #dcdfe6;
  line-height: 114px;
  color: #080707;
  .item {
    border: none;
    // display: flex;
    // flex-direction: column;
    // flex-wrap: wrap;
    // justify-content: center;
  }
  .number {
    text-align: right;
  }
  .item-child {
    padding-left: 20px;
  }
  .icon-xiala {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}
.add-cars {
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  height: 32px;
  color: #080707;
  background: #fff;
  overflow: hidden;
  .car-button {
    width: 28px;
    background: #fafaf9;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .reduce {
    border-right: 1px solid #e1e1e1;
    // margin-right: 20px;
  }
  .reduce:hover {
    border: 1px solid #2d6cfc;
    color: #2d6cfc;
  }
  .add {
    border-left: 1px solid #e1e1e1;
    // margin-left: 20px;
  }
  .add:hover {
    border: 1px solid #2d6cfc;
    color: #2d6cfc;
  }
}
.discount {
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.option {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.bodyContent {
  border-radius: 3px;
}
.contentHeader {
  color: black;
  display: flex;
  align-items: center;
  padding: 2px 20px;
  padding-bottom: 11px;
}

.borderTop {
  border-top: 1px solid #dcdfe6;
  margin: 0px -9px;
}

.contentHeader .icon,
.titlePosition .icon {
  width: 24px;
  height: 24px;
}
.contentHeader > div {
  font-size: 14px;
  font-weight: 600;
  margin-right: 15px;
}
.contentBody {
  padding: 20px;
  ::v-deep.el-input__inner {
    width: 170px;
    height: 30px;
    margin-left: 15px;
  }
  ::v-deep.el-table th {
    padding: 5px 0;
  }
  ::v-deep.el-table td {
    padding: 5px 0;
  }
}
</style>