<template>
  <div class="ordering-shopping-car">
    <div class="platform">
      <div class="title">
        <div class="name">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-pay"></use>
          </svg>
          <!-- 购物车 -->
          <span class="word">{{
            $t("label.partnerCloud.orderingPlatform.shoppingCart")
          }}</span>
          <!-- 币种： -->
          <span class="currency"
            >{{ $t("label.partnerCloud.orderingPlatform.currency")
            }}{{ currency }}</span
          >
        </div>
        <!-- 添加产品 -->
        <div class="button" @click="linkProduct">
          {{ $t("label.continue.shopping") }}
        </div>
      </div>
      <div class="body">
        <!-- 产品列表 -->
        <div class="product product-title">
          <div class="item" style="width: 64px">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
            ></el-checkbox>
          </div>
          <div
            class="item titles"
            v-for="(item, index) in titleDate"
            :key="index"
            :style="{ width: item.width ? item.width : '0px' }"
          >
            <span
              v-if="
                (item.title == item) == $t('label.quote.zhekou') ||
                item == $t('label.stdashbord.xsld.business.jine') ||
                item == $t('c5') ||
                item == $t('lable.product.zongjia')
              "
              >{{ item.title }}({{ currency }})</span
            >
            <span v-else>{{ item.title }}</span>
          </div>
        </div>
        <div
          class="product-list"
          v-loading="carLoading"
          :class="listDate.length > 0 ? 'product-list-line' : ''"
        >
          <div class="product-body" v-if="listDate.length > 0">
            <order-car-table
              v-for="item in listDate"
              ref="orderCarTables"
              :key="item.product"
              :item="item"
              :allDataArr="allDataArr"
              :checkAll="checkAll"
              :isSelectData="isSelectData"
              :child="false"
              :checked="item.checked"
              @deleteSelectCar="deleteSelectCar"
              @changeCheckout="changeCheckout"
            ></order-car-table>
          </div>
          <div class="no-data" v-if="listDate.length == 0">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-weichuli"></use>
            </svg>
            <!-- 购物车为空，请点击添加产品按钮进行添加 -->
            <p>{{ $t("label.partnerCloud.orderingPlatform.shoppingEmpty") }}</p>
          </div>
        </div>
      </div>
      <div class="page-bottom" v-if="listDate.length > 0">
        <div>
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >{{ $t("label.event.select") }}（{{
              isSelectData.length
            }}）</el-checkbox
          >
          <div class="delete" @click="deleteSelectCar('all')">
            {{ $t("c95") }}
          </div>
        </div>
        <div class="computed">
          <div class="price">
            <div style="margin-bottom: 5px" v-show="productQuantity != 0">
              <span class="bottom-style"
                >{{ $t("c98") }}：
                <p>{{ thousands(productQuantity) }}</p>
                {{ $t("c120") }} </span
              >|<span class="bottom-style"
                >{{ $t("c99") }}：
                <p>{{ thousands(totalCount) }}</p>
                {{ $t("c101") }}</span
              >|<span class="bottom-style"
                >{{ $t("lable.product.zongjia") }}：
                <p class="money">
                  {{ currency }} {{ thousands(totalPrice) }}
                </p></span
              >
            </div>
            <div style="margin-bottom: 5px" v-show="productQuantity == 0">
              <span class="bottom-style"
                >{{ $t("c98") }}：
                <p>{{ productQuantity }}</p>
                {{ $t("c120") }} </span
              >|<span class="bottom-style"
                >{{ $t("c99") }}：
                <p>{{ totalCount }}</p>
                {{ $t("c101") }}</span
              >|<span class="bottom-style"
                >{{ $t("lable.product.zongjia") }}：
                <p class="money">{{ currency }} {{ totalPrice }}</p></span
              >
            </div>
            <!-- 订单金额： label.partnerCloud.orderingPlatform.orderAmount -->
            <div class="span" v-show="productQuantity != 0">
              {{ $t("label.discount") }}：{{ currency }}
              {{ thousands(Reduction) }}
            </div>
            <div class="span" v-show="productQuantity == 0">
              {{ $t("label.discount") }}：{{ currency }} {{ Reduction }}
            </div>
          </div>
          <!-- 计算价格 -->
          <!-- <div class="button" v-show="changePrice" @click="calculatePrice">
            {{ $t('label.partnerCloud.orderingPlatform.calculate') }}
          </div> -->
          <!-- 创建订单 -->
          <div class="button" @click="createOrder">
            {{ $t("label.partnerCloud.orderingPlatform.createOrder") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import orderCarTable from "./components/order-car-table.vue";
import {
  queryShopping,
  deleteShoppingCart,
  SaveShoppingCart,
  queryChooseProduct,
} from "./api.js";
export default {
  data() {
    return {
      //币种
      currency: this.$store.state.currencyCode,
      oldImgSrcBefore: `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=`,
      oldImgSrcAfter: `&binding=${this.$CCDK.CCToken.getToken()}`,
      //判断显示哪个按钮
      changePrice: true,
      //选中的数据
      isSelectData: [],
      //所有数据id数组
      allDataArr: [],
      //价格手册id
      pricebook: "",
      //产品数量
      productQuantity: 0,
      //总共件数
      totalCount: 0,
      //原价
      originalPrice: 0,
      //折减
      Reduction: 0,
      //订单金额
      totalPrice: 0,
      //购物车数据loading
      carLoading: false,
      prefix: "032",
      objectApi: "cloudccorder",
      //当前选中的数据
      checkList: [],
      //产品表头数据     产品图片 产品代码  产品名称  单价 购买数量 优惠 总价 操作
      titleDate: [
        { title: this.$i18n.t("c122"), width: "142px" },
        {
          title: this.$i18n.t("label.product.name"),
          width: "calc(100% - 1227px)",
        },
        { title: this.$i18n.t("label.product.code"), width: "217px" },
        {
          title: this.$i18n.t("label.partnerCloud.orderingPlatform.unitPrice"),
          width: "120px",
        },
        {
          title: this.$i18n.t("label.partnerCloud.orderingPlatform.quantity"),
          width: "140px",
        },
        { title: this.$i18n.t("label.quote.zhekou"), width: "120px" },
        {
          title: this.$i18n.t("label.stdashbord.xsld.business.jine"),
          width: "120px",
        },
        { title: this.$i18n.t("c5"), width: "120px" },
        { title: this.$i18n.t("lable.product.zongjia"), width: "120px" },
        { title: this.$i18n.t("label.import.index.operation"), width: "64px" },
      ],
      listDate: [],
      //搜索产品关键字
      productWord: "",
      //当前展示输入框的下标
      inputIndex: null,
      // 订单id
      orderId: "",
      priceBookName: this.$route.query.priceBookName,
      //折扣弹框loading
      discountLoading: false,
      //用于实现全选的效果
      isIndeterminate: false,
      //是否全选
      checkAll: false,
      cartInfo: {},
      brr: [],
      //生成订单页面数据
      orderArr: [],
    };
  },
  components: {
    orderCarTable,
  },
  mounted() {
    document.title = this.$setTitle(this.$i18n.t("label.ordering.platform"));
    this.carLoading = true;
    this.queryShopping("first");
    this.$Bus.$off("query-car-data");
    this.$Bus.$on("query-car-data", this.ebFn);
  },
  beforeDestroy(){
    this.$Bus.$off("query-car-data", this.ebFn);
  },
  watch: {},
  methods: {
    ebFn(){
      this.calculatePrice();
    },
    // 设置千分位样式  dj
    thousands(num) {
      if (num) {
        var parts = num.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }
    },
    //更改选中状态
    async changeCheckout(val) {
      this.isSelectData = [];
      this.listDate.forEach((res) => {
        if (res.product == val.product) {
          res.checked = val.checked;
        }
      });
      this.listDate.forEach((res) => {
        if (res.checked == true) {
          this.isSelectData.push(res.product);
        }
      });
      //如果选中为0，全选按钮置灰
      if (this.isSelectData.length == 0) {
        this.isIndeterminate = false;
        this.checkAll = false;
      }
      if (this.isSelectData.length > 0) {
        this.isIndeterminate = true;
      }
      if (this.isSelectData.length == this.allDataArr.length) {
        this.checkAll = true;
        this.isIndeterminate = false;
      }
      this.queryCurrent();
    },
    handleCheckAllChange(val) {
      if (val) {
        this.listDate.forEach((res) => {
          res.checked = true;
        });
      } else {
        this.listDate.forEach((res) => {
          res.checked = false;
        });
      }
      this.isIndeterminate = false;
    },
    //获取当前选中产品价格
    async queryCurrent() {
      let result = await queryChooseProduct({
        productids: this.isSelectData.join(),
      });
      if (result.result) {
        if (result.data == null) {
          this.productQuantity = 0;
          this.totalCount = 0;
          this.Reduction = 0;
          this.originalPrice = 0;
          this.totalPrice = 0;
          return false;
        }
        this.productQuantity = 0;
        this.brr = [];
        this.carLoading = false;
        this.pricebook = "";
        this.totalCount = 0;
        this.Reduction = 0;
        this.originalPrice = 0;
        this.totalPrice = 0;
        if (result.data && result.data.productList.length > 0) {
          this.pricebook = result.data.pricebook2id;
          let arr = [];
          let brr = [];
          result.data.productList = this.changeCarData(result.data.productList);
          result.data.productList.forEach((res) => {
            if (res.parentproduct == "null") {
              this.productQuantity++;
            }
            arr.push(res.quantity);
            brr.push(Number(res.listprice) * Number(res.quantity));
          });
          this.orderArr = result.data.productList;
          this.totalCount = eval(arr.join("+"));
          // shopItem 税
          let shopItem = 0;
          result.data.productList.forEach((element) => {
            shopItem += Number(element.retaprice);
          });
          // 总折扣：原总价 - 总价 + 税
          this.Reduction =
            eval(result.data.productList[0].reduction.join("+")).toFixed(2) -
            Number(result.data.total) +
            shopItem;
          this.Reduction = this.Reduction.toFixed(2);
          this.originalPrice = eval(brr.join("+")).toFixed(2);
          this.totalPrice = Number(result.data.total).toFixed(2);
          //产品种类
          this.cartInfo.productQuantity = this.productQuantity;
          // 产品数量
          this.cartInfo.totalCount = this.totalCount;
          // 总价
          this.cartInfo.totalPrice = this.totalPrice;
          //折扣
          this.cartInfo.Reduction = this.Reduction;
        }
      }
    },
    //计算价格参数
    calculatePriceParams(data) {
      let arr = [];
      data.forEach((res) => {
        let obj = {
          product: res.product,
          productoption: res.productoption,
          quantity: res.quantity,
          childproduct: [],
        };
        if (res.childproduct && res.childproduct.length > 0) {
          obj.childproduct = this.calculatePriceParams(res.childproduct);
          // 子产品中的数量"quantity"修改为"quantitysetup"
          obj.childproduct = JSON.parse(
            JSON.stringify(obj.childproduct).replace(
              /quantity/g,
              "quantitysetup"
            )
          );
        }
        arr.push(obj);
      });
      return arr;
    },
    //计算价格
    async calculatePrice() {
      if (this.listDate.length == 0) {
        return false;
      }
      let option = {
        pricebook: this.pricebook,
        productJson: JSON.stringify(this.calculatePriceParams(this.listDate)),
      };
      let result = await SaveShoppingCart(option);
      if (result.result) {
        this.queryShopping();
        if (this.isSelectData.length != 0) {
          this.queryCurrent();
        }
        this.changePrice = false;
      }
    },
    //更改购物车数据
    changeCarData(data, first) {
      let arr = [];
      data.forEach((item) => {
        let res = item;
        if (!res.listprice) {
          res.listprice = "0.00";
        }
        if (!res.retaprice) {
          res.retaprice = "0.00";
        }
        res.retaprice = Number(res.retaprice).toFixed(2);
        res.isChild = false;
        if (first == "first") {
          res.checked = true;
        } else {
          let findIndex = this.listDate.findIndex(
            (ele) => ele.product == item.product
          );
          if (findIndex >= 0) {
            res.checked = this.listDate[findIndex].checked;
          }
        }
        res.quantity = Math.floor(res.quantity);
        res.discount =
          Number(res.listprice).toFixed(2) * Number(res.quantity).toFixed(2) -
          Number(res.customertotal).toFixed(2);
        res.discount = Math.abs(res.discount).toFixed(2);
        res.zongjia = (
          Number(res.customertotal) + Number(res.retaprice)
        ).toFixed(2);
        if (item.childproduct && item.childproduct.length > 0) {
          res.childproduct = this.changeCarData(res.childproduct);
        }
        arr.push(res);
        this.brr.push(Number(res.listprice) * Number(res.quantity));
        item.reduction = this.brr;
      });
      return arr;
    },
    //删除选中行数据
    async deleteSelectCar(product, item) {
      let products = [];
      if (product == "all") {
        if (this.isSelectData.length == 0) {
          this.$message.warning(
            this.$i18n.t("vue_label_opportunity_related_chooseproduct")
          );
          return false;
        }
        products = this.isSelectData;
      } else {
        products = [product];
      }
      let result = await deleteShoppingCart({ prdcuctlist: products });
      if (result.result) {
        this.$message.success(this.$i18n.t("label.tabpage.delsuccessz")); //删除成功
        this.queryShopping("delete", item);
        this.changePrice = true;
        this.checkAll = false;
        this.isIndeterminate = false;
        this.isSelectData = [];
      }
    },
    //获取购物车数据
    async queryShopping(first, deleteItem) {
      // this.productQuantity = 0
      this.allDataArr = [];
      this.brr = [];
      let result = await queryShopping();
      if (result.result) {
        this.carLoading = false;
        if (result.data) {
          this.pricebook = result.data.pricebook2id;
          let arr = [];
          let brr = [];
          result.data.productList = this.changeCarData(
            result.data.productList,
            first
          );
          result.data.productList.forEach((res) => {
            this.allDataArr.push(res.product);
            if (res.parentproduct == "null" && first == "first") {
              this.productQuantity++;
            }
            arr.push(res.quantity);
            brr.push(Number(res.listprice) * Number(res.quantity));
          });
          this.listDate = result.data.productList;
          this.orderArr = this.listDate;
          if (first == "first") {
            this.isSelectData = this.listDate.map((item) => item.product);
          }
          if (first == "first") {
            this.totalCount = eval(arr.join("+"));
            // shopItem 税
            let shopItem = 0;
            this.listDate.forEach((element) => {
              shopItem += Number(element.retaprice);
            });
            // 总折扣：原总价 - 总价 + 税
            this.Reduction =
              eval(this.listDate[0].reduction.join("+")).toFixed(2) -
              Number(result.data.total) +
              shopItem;
            this.Reduction = this.Reduction.toFixed(2);
            this.originalPrice = eval(brr.join("+")).toFixed(2);
            this.totalPrice = Number(result.data.total).toFixed(2);
            this.checkAll = true;
            //产品种类
            this.cartInfo.productQuantity = this.productQuantity;
            // 产品数量
            this.cartInfo.totalCount = this.totalCount;
            // 总价
            this.cartInfo.totalPrice = this.totalPrice;
            //折扣
            this.cartInfo.Reduction = this.Reduction;
          }
        } else {
          this.listDate = [];
        }
        if (first == "delete") {
          this.changeCheckout(deleteItem);
        }
      }
    },
    createOrder() {
      if (this.orderArr.length == 0) {
        return false;
      }
      this.$router.push({
        path: "/createOrder",
        query: {
          listDate: JSON.stringify(this.orderArr),
          totalPrice: this.totalPrice,
          priceBookName: this.$route.query.priceBookName,
          cartInfo: this.cartInfo,
        },
      });
    },
    handleCheckedListChange(val) {
      let checkedCount = val.length;
      this.checkAll = checkedCount === this.allDataArr.length;
      this.isSelectData = val;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.allDataArr.length;
    },
    //跳转购物车
    linkProduct() {
      this.$router.push({
        path: "/orderingPlatform",
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.name .icon {
  width: 30px;
  height: 30px;
}
.ordering-shopping-car {
  padding: 10px;
  height: 100%;
  ::v-deep .el-checkbox .el-checkbox__label {
    display: none;
  }
  .platform {
    position: relative;
    width: 100%;
    height: 100%;
    user-select: none;
    // background-color: #f5f5f5;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      border-radius: 2px 2px 3px 3px;
      border: 1px solid #dddbda;
      .name {
        display: flex;
        align-items: center;
        .word {
          margin-left: 5px;
          font-size: 15px;
          font-weight: bold;
        }
        .currency {
          margin-left: 15px;
          font-size: 12px;
        }
      }
      .button {
        padding: 5px 10px;
        border: 1px solid #dcdfe6;
        border-radius: 3px;
        cursor: pointer;
        background: white;
        color: #080707;
      }
    }
    .body {
      width: 100%;
      height: calc(100% - 60px);
      background-color: #fff;
      margin-top: 10px;
      .product-title {
        background: #fafaf9;
        font-weight: 600;
        margin-top: 10px;
        border: 1px solid #dcdfe6;
        border-radius: 2px 2px 0 0;
        color: #393939;
      }
      .product {
        line-height: 40px;
        height: 40px;
        overflow: hidden;
        .item {
          // text-align: center;
          float: left;
          width: 120px;
          border-right: 1px solid #dcdfe6;
          height: 100%;
          padding: 0 10px;
        }
        .discount-small {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
        ::v-deep.el-popover__reference {
          display: inline-block;
        }
        ::v-deep.el-popover__reference-wrapper {
          display: inline-block;
        }
        input {
          outline: none;
          height: 30px;
          width: 100%;
          border-radius: 4px;
          border: 1px solid #dcdcdc;
          padding-left: 5px;
          box-sizing: border-box;
        }
        .zhekou {
          width: 100px;
          text-align: right;
        }
        .count {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 140px;
          img {
            margin-left: 5px;
          }
        }
        .discount img {
          width: 20px;
          cursor: pointer;
        }
        .picture {
          display: flex;
          width: 142px;
        }
        .productname {
          width: calc(100% - 1207px);
        }
        .cpdm {
          width: 217px;
        }
        ::v-deep .picture img {
          width: 60px;
        }
        // .item:nth-child(1) {
        //   width: 42px;
        // }
        .item:last-child {
          border: none;
          width: 64px;
          cursor: pointer;
        }
        .titles:last-child {
          text-align: center;
        }
      }
      .product-list-line {
        // border-bottom: 1px solid #dcdfe6;
      }
      .product-list {
        max-height: calc(100% - 110px);
        overflow: overlay;
        .no-data {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 300px;
            margin: 100px 0 15px;
          }
          p {
            margin-right: 70px;
            font-weight: bold;
          }
        }
        .product-body {
          width: 100%;
        }
      }
    }
    .page-bottom {
      width: 100%;
      height: 60px;
      border-top: 1px solid #dcdfe6;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      ::v-deep.el-checkbox .el-checkbox__label {
        display: inline-block;
        color: #080707;
      }
      .delete {
        padding: 0 10px;
        height: 32px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #e1e1e1;
        color: #080707;
        line-height: 32px;
        text-align: center;
        margin-left: 10px;
        display: inline-block;
        cursor: pointer;
      }
      .computed {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .price {
          margin-right: 30px;
          .span {
            font-size: 14px;
            float: right;
            margin-right: 20px;
            color: #f11b21;
          }
          .bottom-style {
            margin: 0 16px;
            p {
              font-weight: bold;
              display: inline-block;
            }
            .money {
              color: #f11b21;
              font-weight: bold;
              font-size: 20px;
            }
          }
        }
        .button {
          cursor: pointer;
          border: 1px solid #dcdfe6;
          padding: 8px 12px;
          margin-right: 5px;
          color: #fff;
          background: #2d6cfc;
          border-radius: 4px 4px 4px 4px;
        }
      }
    }
  }
  /* Chrome浏览器 */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  /* Firefox浏览器 */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.discount {
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-data .icon {
  width: 300px;
  height: 196px;
  margin: 100px 0 15px;
}
</style>
